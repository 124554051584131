module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SLEM","short_name":"SLEM","start_url":"/","background_color":"#f3f4f6","theme_color":"#f3f4f6","display":"standalone","icon":"src/assets/svg/logo.svg","icons":[{"src":"src/assets/images/logo.png","sizes":"196x196"},{"src":"src/assets/images/maskable_logo.png","sizes":"196x196","purpose":"maskable"},{"src":"src/assets/images/splash_logo.png","sizes":"512x512"}],"theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8363fc44c260c381db2ee88a859a9a9f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
